import { noData } from "../../Assets/images/svg";
import "./style.scss";

function NoData({ 
  text="No data is available",
  fontSize="f-24",
  backGroundNone
 }) {
  return (
    <div className={`no-data-container d-flex  flex-column justify-content-center align-items-center ${backGroundNone?"no-background d-flex justify-content-center align-items-center ":""}`}>
      <div>{noData}</div>
      <div className={`content fw-400 mt-2 text-center ${fontSize}`}>{text}</div>
    </div>
  );
}

export default NoData;
