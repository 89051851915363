import axios from "axios";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

import "../App.scss";

export const getToken = () => {
  return localStorage.getItem("token");
};
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Swal
const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    cancelButton: "btn swal-cancel-button",
    icon: "custom-swal-icon",
  },
});

export const callSwal = (message) => {
  swalWithBootstrapButtons.fire({
    timer: 3000,
    width: 520,
    showCancelButton: true,
    cancelButtonText: "Close",
    reverseButtons: false,
    showConfirmButton: false,
    showCloseButton: true,
    html: `
  <div class="swal-custom-html">
  <h2 class="swal-custom-html-title">Oops!</h2>
    <svg xmlns="http://www.w3.org/2000/svg" width="104" height="103" viewBox="0 0 104 103" fill="none">
      <path d="M51.9997 72.957C53.2156 72.957 54.2356 72.545 55.0596 71.721C55.8836 70.897 56.2942 69.8785 56.2913 68.6654C56.2913 67.4494 55.8793 66.4294 55.0553 65.6054C54.2313 64.7814 53.2128 64.3708 51.9997 64.3737C50.7837 64.3737 49.7637 64.7857 48.9397 65.6097C48.1157 66.4337 47.7052 67.4523 47.708 68.6654C47.708 69.8813 48.12 70.9013 48.944 71.7253C49.768 72.5493 50.7866 72.9599 51.9997 72.957ZM47.708 55.7904H56.2913V30.0404H47.708V55.7904ZM51.9997 94.4154C46.0629 94.4154 40.4837 93.2881 35.2622 91.0335C30.0406 88.779 25.4986 85.7219 21.6361 81.8623C17.7736 77.9997 14.7165 73.4577 12.4648 68.2362C10.2131 63.0147 9.08587 57.4355 9.08301 51.4987C9.08301 45.5619 10.2103 39.9827 12.4648 34.7612C14.7194 29.5397 17.7765 24.9977 21.6361 21.1352C25.4986 17.2727 30.0406 14.2156 35.2622 11.9639C40.4837 9.71217 46.0629 8.58489 51.9997 8.58203C57.9365 8.58203 63.5156 9.70931 68.7372 11.9639C73.9587 14.2184 78.5007 17.2755 82.3632 21.1352C86.2257 24.9977 89.2842 29.5397 91.5388 34.7612C93.7934 39.9827 94.9192 45.5619 94.9164 51.4987C94.9164 57.4355 93.7891 63.0147 91.5345 68.2362C89.28 73.4577 86.2229 77.9997 82.3632 81.8623C78.5007 85.7247 73.9587 88.7833 68.7372 91.0378C63.5156 93.2924 57.9365 94.4182 51.9997 94.4154Z" fill="#D3203D"/>
    </svg>
    <p class="swal-custom-html-text">${message}</p>
  </div>
`,
  });
};

export const get = async (endpoint, method = "GET", data = null) => {
  try {
    // Wait for the token to be prefetched
    const token = await getToken();
    instance.defaults.headers.common["Token"] = ` ${token}`;
    const response = await instance.request({
      url: endpoint,
      method,
      data,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 404 || error?.response?.status === 400) {
      return error.response.data;
    } else {
      callSwal("Something went wrong. Please try again");
      return null;
    }
  }
};

export const post = async (endpoint, data = {}, method = "POST") => {
  try {
    // Wait for the token to be prefetched
    const token = await getToken();
    instance.defaults.headers.common["Token"] = `${token}`;
    const response = await instance.request({
      url: endpoint,
      method,
      data,
    });
    return response?.data;
  } catch (error) {
    if (error?.response?.status === 404 || error?.response?.status === 400) {
      return error.response.data;
    } else {
      callSwal("Something went wrong. Please try again");
      return null;
    }
  }
};

// create a function to make POST requests
// export const post = async (url, data = {}) => {
//   try {
//     const response = await instance.post(url, data);
//     if (response.status === 200 || response.status === 400) {
//       return response.data;
//     }
//   } catch (error) {
//     return error.response.data;
//   }
// };
