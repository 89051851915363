import React from "react";
import { Modal } from "react-bootstrap";
import Button from "../Form/Button";
import "./style.scss";

const ConfirmationPopup = React.memo(
  ({
    show,
    onClose,
    text,
    size = "md",
    className,
    buttonPrimary,
    buttonSecondary,
    modalClassName,
    dialogClassName,
    handleConfirm,
    loading,
    backdropClassName = "form-modal-backdrop",
  }) => {
    return (
      <Modal
        show={show}
        centered
        onHide={onClose}
        size={size}
        className={modalClassName}
        dialogClassName={dialogClassName}
        backdropClassName={backdropClassName}
      >
        <Modal.Body className={className}>
          <p className="f-24 fw-500 lh-32 text-center conformation-title">
            {text}
          </p>
          <div className="d-flex justify-content-space-between px-5 gap-4">
            <Button
              className="btn-no btn-padding f-16 fw-700"
              onClick={onClose}
            >
              {buttonSecondary}
            </Button>
            <Button
              className="btn-yes btn-padding f-16 fw-700"
              onClick={handleConfirm}
              loading={loading}
            >
              {buttonPrimary}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
);

export default ConfirmationPopup;
