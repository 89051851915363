import React, { Suspense } from "react";

// Routes
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

// components
import Layout from "./Layout";
import PrivateRoute from "./PrivateRoute/index";
import Loader from "./Components/Loader";
// styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

// import StockExecution from "./Pages/StockExecution";

// Lazy loading

const AssignedUserList = React.lazy(() => import("./Pages/AssignedUser"));
const DashboardPage = React.lazy(() => import("./Pages/DashBoard"));
const AppUserPage = React.lazy(() => import("./Pages/AppUser/index"));
const Login = React.lazy(() => import("./Pages/Login/login"));
const RiaUserPage = React.lazy(() => import("./Pages/RiaUser/index"));
const UserProfile = React.lazy(() => import("./Pages/Profile"));
const ProfilePage = React.lazy(() => import("./Pages/UserProfile/index"));
const ApUserPage = React.lazy(() => import("./Pages/ApUser"));
const ManageUserPage = React.lazy(() => import("./Pages/ManageUser"));
const Stocks = React.lazy(() => import("./Pages/Stocks"));
const ViewUserDetailsPage = React.lazy(() => import("./Pages/ViewUserDetails"));
const ChatPage = React.lazy(() => import("./Pages/Chat"));
const UserDocument = React.lazy(() => import("./Pages/UserDocument"));
const SuggestStock = React.lazy(() => import("./Pages/SuggestStock"));
const ReSuggestStock = React.lazy(() => import("./Pages/ReSuggestStock"));
const IncomingRequests = React.lazy(() => import("./Pages/Incoming"));
const AppUserProfile = React.lazy(() => import("./Pages/AppUserProfile"));
const NotificationPage = React.lazy(() => import("./Pages/Notification"));
const TransactionsPage = React.lazy(() => import("./Pages/TransactionHistory"));
const StockExecution = React.lazy(() => import("./Pages/StockExecution"));
const DeleteReason = React.lazy(() =>
  import("./Pages/AppUser/Components/DeleteReason")
);
const Deactivated = React.lazy(() => import("./Pages/Deactivated"));

function App() {
  return (
    <Router>
      <Routes>
        {[
          "/",
          "/login",
          "/forgotPassword",
          "/changePassword",
          "/resetPassword/:token",
        ].map((path) => (
          <Route
            path={path}
            key={path}
            element={
              <Suspense fallback={<Loader />}>
                <Login />
              </Suspense>
            }
          ></Route>
        ))}
          <Route
            path="deactivated-user/:id"
            index
            element={
              <Suspense fallback={<Loader />}>
                <Deactivated />
              </Suspense>
            }
          />
        <Route
          path="/"
          element={
            <Suspense fallback={<Loader />}>
              <PrivateRoute>
                <Layout hideSideBar />
              </PrivateRoute>
            </Suspense>
          }
        >
          {["chat", "chat/:chatId"].map((item) => {
            return (
              <Route
                path={item}
                key={item}
                element={
                  <Suspense fallback={<Loader />}>
                    <ChatPage />
                  </Suspense>
                }
              />
            );
          })}
        
        </Route>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loader />}>
              <PrivateRoute>
                <Layout />
              </PrivateRoute>
            </Suspense>
          }
        >
          <Route
            path="manage/app-user"
            element={
              <Suspense fallback={<Loader />}>
                <AppUserPage />
              </Suspense>
            }
          />

          <Route
            path="dashboard"
            index
            element={
              <Suspense fallback={<Loader />}>
                <DashboardPage />
              </Suspense>
            }
          />
          
          <Route
            path="dashboard/incoming-requests"
            element={
              <Suspense fallback={<Loader />}>
                <IncomingRequests />
              </Suspense>
            }
          />
          {[
            "dashboard/stock-execution",
            "dashboard/stock-execution/:userId",
            "notification/stock-execution/:userId/:stockId",
          ].map((item) => {
            return (
              <Route
                path={item}
                key={item}
                element={
                  <Suspense fallback={<Loader />}>
                    <StockExecution />
                  </Suspense>
                }
              />
            );
          })}

          <Route
            path="manage/app-user"
            element={
              <Suspense fallback={<Loader />}>
                <AppUserPage />
              </Suspense>
            }
          />
          <Route
            path="manage/app-user/view-profile"
            element={
              <Suspense fallback={<Loader />}>
                <UserProfile />
              </Suspense>
            }
          />
          {[
            "manage/ria-user/view-profile/:userType/:profileId",
            "manage/ap-user/view-profile/:userType/:profileId",
            "dashboard/view-profile/:userType/:profileId",
            "/profile/:userType/:profileId",
          ].map((item) => {
            return (
              <Route
                path={item}
                key={item}
                element={
                  <Suspense fallback={<Loader />}>
                    <ProfilePage />
                  </Suspense>
                }
              />
            );
          })}
          <Route
            path="manage/ria-user"
            element={
              <Suspense fallback={<Loader />}>
                <RiaUserPage />
              </Suspense>
            }
          />

          <Route
            path="manage/ap-user"
            element={
              <Suspense fallback={<Loader />}>
                <ApUserPage />
              </Suspense>
            }
          />
          <Route
            path="manage/user"
            element={
              <Suspense fallback={<Loader />}>
                <ManageUserPage />
              </Suspense>
            }
          />
          {["stock", "stock/:type"].map((item) => {
            return (
              <Route
                path={item}
                key={item}
                element={
                  <Suspense fallback={<Loader />}>
                    <Stocks />
                  </Suspense>
                }
              />
            );
          })}
          {[
            "manage/app-user/view-details/:id",
            "dashboard/view-details/:id",
            "dashboard/incoming-requests/view-details/:id",
          ].map((item) => {
            return (
              <Route
                path={item}
                key={item}
                element={
                  <Suspense fallback={<Loader />}>
                    <AppUserProfile />
                  </Suspense>
                }
              />
            );
          })}
          {[
            "manage/user/view-details/:profileId",
            "notification/view-details/:profileId",
          ].map((item) => {
            return (
              <Route
                path={item}
                key={item}
                element={
                  <Suspense fallback={<Loader />}>
                    <ViewUserDetailsPage />
                  </Suspense>
                }
              />
            );
          })}

          {[
            "manage/ria-user/assigned-user-details/:adminUserType/:adminUserId",
            "manage/ap-user/assigned-user-details/:adminUserType/:adminUserId",
          ].map((item) => {
            return (
              <Route
                path={item}
                key={item}
                element={
                  <Suspense fallback={<Loader />}>
                    <AssignedUserList />
                  </Suspense>
                }
              />
            );
          })}
          <Route
            path="deleteReason/:userId"
            element={
              <Suspense fallback={<Loader />}>
                <DeleteReason />
              </Suspense>
            }
          />
          <Route
            path="user-document/:userId"
            element={
              <Suspense fallback={<Loader />}>
                <UserDocument />
              </Suspense>
            }
          />
          {[
            "/stock/suggest-stock",
            "/stock/suggest-stock/:suggestedStockId",
          ].map((item) => {
            return (
              <Route
                path={item}
                key={item}
                element={
                  <Suspense fallback={<Loader />}>
                    <SuggestStock />
                  </Suspense>
                }
              />
            );
          })}
          {["/stock/re-suggest-stock/:suggestedStockId"].map((item) => {
            return (
              <Route
                path={item}
                key={item}
                element={
                  <Suspense fallback={<Loader />}>
                    <ReSuggestStock />
                  </Suspense>
                }
              />
            );
          })}
          {["/dashboard/notification"].map((item) => {
            return (
              <Route
                path={item}
                key={item}
                element={
                  <Suspense fallback={<Loader />}>
                    <NotificationPage />
                  </Suspense>
                }
              />
            );
          })}
          {[
            "/dashboard/transaction/:userId/:transactionId",
            "/manage/app-user/transaction/:userId",
          ].map((item) => {
            return (
              <Route
                path={item}
                key={item}
                element={
                  <Suspense fallback={<Loader />}>
                    <TransactionsPage />
                  </Suspense>
                }
              />
            );
          })}
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
