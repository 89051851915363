import { currentYear, formatDateTime, isDateOfBirthValid } from ".";
import {
  alphabeticRegex,
  emailRegex,
  panNumberRegx,
  phoneNumberRegx,
  requiredField,
} from "./regex";

const errFields = {
  username: {
    regex: alphabeticRegex,
    message: "Invalid user name",
  },
  email: {
    regex: emailRegex,
    message: "Invalid Email ID",
  },
  phone_number: {
    regex: phoneNumberRegx,
    message: "Invalid mobile number",
    minLength: 10,
  },
  pan_number: {
    regex: panNumberRegx,
    message: "Invalid PAN number",
    minLength: 10,
  },
  expiry_date: {
    dateValidator: (value) =>
      !isDateOfBirthValid(formatDateTime(value, "dd/mm/yyyy")),
    message: "Invalid expiry date",
  },
  password: {
    customValidator: (value, data, key) =>
      key === "changePassword" ? value === data.oldPassword : false,
    message: "New password is the same as the old password",
  },
  confirmPassword: {
    customValidator: (value, data) => value !== data.password,
    message: "Password does not match",
  },
};

const formFields = {
  changePassword: ["oldPassword", "password", "confirmPassword"],
  userDetails: ["username", "dob", "pan_number", "phone_number", "email"],
  userAdditionalInfo: [
    "certificate_name",
    "license_number",
    "expiry_date",
    "company_name",
    "job_title",
    "years_of_experience",
    "college_name",
    "degree",
    "duration",
  ],
  login: ["email", "password"],
  choiceConnection: ["client_id", "password"],
  forgetPassword: ["email"],
  resetPassword: ["password", "confirmPassword"],
  suggestStock: ["stop_loss", "max_price"],
};

const validateField = (data, item, key) => {
  const errors = {};
  const value = data[item];
  const fieldConfig = errFields[item];

  if (!value) {
    errors[item] = requiredField;
  }

  if (
    fieldConfig?.customValidator &&
    fieldConfig?.customValidator(value, data, key)
  ) {
    errors[item] = fieldConfig.message;
  }

  if (fieldConfig?.regex && !value.match(fieldConfig?.regex)) {
    errors[item] = fieldConfig.message;
  }

  if (fieldConfig?.minLength && value.length < fieldConfig?.minLength) {
    errors[item] = fieldConfig.message;
  }

  if (fieldConfig?.dateValidator && fieldConfig?.dateValidator(value)) {
    errors[item] = fieldConfig.message;
  }

  return errors;
};

export function errorCheck(data, key) {
  const fields = formFields[key] || [];
  let errors = {};
  for (const field of fields) {
    if (Object.prototype.hasOwnProperty.call(data, field)) {
      const fieldErrors = validateField(data, field, key);
      errors = { ...errors, ...fieldErrors };
    }
  }
  
  const fromYear = parseInt(data?.from_year);
  const toYear = parseInt(data?.to_year);
  
  if ((Object.prototype.hasOwnProperty.call(data,"from_year") && fromYear > currentYear) || fromYear < 1950) {
    errors["from_year"] = "Invalid year";
  }
  if ((Object.prototype.hasOwnProperty.call("to_year") && toYear > currentYear) ||
    toYear < 1950 ||
    fromYear > toYear
  ) {
    errors["to_year"] = "Invalid year";
  }
  return errors;
}
