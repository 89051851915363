import { toast } from "react-toastify";
const customStyle = {
  width: "454px",
  height: "42px",
  top: "6em",
  right: "8em",
};
export const showToast = (message, options = {}) => {
  toast(message, { ...options, style: customStyle });
};

export const showToastSuccess = (message) => {
  showToast(message, {
    type: toast.TYPE.SUCCESS,
  });
};

export const showToastError = (message) => {
  showToast(message, {
    type: toast.TYPE.ERROR,
  });
};
