import { createSlice } from "@reduxjs/toolkit";

const tableReducer = createSlice({
  name: "table",
  initialState: {
    tabKey:'',
    page:0,
    sizePerPage:10,
  },
  reducers: {
    updateTableData: (state, action) => {
      state.tableData = action.payload;
    },
  },
});

export const { updateTableData } = tableReducer.actions;

export default tableReducer.reducer;
