import { useState, useEffect } from "react";
//lib
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  chatIcon,
  chatImg,
  dashboardImg,
  downArrow,
  logOutImg,
  manageUserImg,
  person,
  stockImg,
  upArrow,
} from "../../Assets/images/svg";
import { get } from "../../Services";
//scss
import ConfirmationPopup from "../../Components/ConfirmationPopup";
import { clearAllData } from "../../ReduxToolkit/loginReducer";
import "./style.scss";
import { pusherInit } from "../../Utilities";
import { getChatCount } from "../../Services/api";
// import { pusherInit } from "../../Utilities";

const Sidebar = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [arrowChange, setArrowChange] = useState(false);
  const [count, setCount] = useState(0);
  const [show, setShow] = useState(false);
  const loginDetails = useSelector((state) => state.login.loginData);
  const role = loginDetails.role_id;
  const userId = loginDetails.user_id;
  const pathName = location.pathname.split("/")[1];
  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {});
    return (
      <ul>
        <li>
          <div
            onClick={decoratedOnClick}
            className={({ isActive }) => (isActive ? "active" : "")}
            role="button"
            aria-hidden
          >
            {children}
          </div>
        </li>
      </ul>
    );
  };

  const handleConfirm = () => {
    setShow(false);
    Promise.all([
      localStorage.clear(),
      sessionStorage.clear(),
    ])
      .then(clearAllData)
      .then(() => {
        navigate("/login");
        // window.location.reload();
      });
  };
  const onClose = () => {
    setShow(false);
  };
  const logutFun = () => {
    setShow(true);
  };
  const getChatMenuCount = async () => {
    const response = await get(`${getChatCount}`);
    if (response?.status) {
      setCount(parseInt(response?.data?.total_unread_count));
    }
  };
  useEffect(() => {
    getChatMenuCount();
  }, [location.pathname]);

  useEffect(() => {
    const ch_name = `${loginDetails?.role?.toLowerCase()}_chat_menu_${userId}`;
    const channel = pusherInit.subscribe(ch_name);
    // Bind to an event on the channel
    channel.bind("chat_menu_unread_count", (response) => {
      setCount(parseInt(response?.total_unread_count));
      // Handle the data received from the event
    });

    // Clean up function
    return () => {
      // Unsubscribe from the channel when the component unmounts
      channel.unbind(); // Unbind all event handlers
      pusherInit.unsubscribe(ch_name);
      // pusherInit.disconnect();
    };
  }, []);
  return (
    <div className="sidebar">
      <nav className={props.handleShow ? "mobileSide" : "sidebar customScroll"}>
        <ul>
          <li>
            <div
              className={
                pathName === "dashboard"
                  ? "nav-border border_active"
                  : "nav-border "
              }
            ></div>
            <NavLink to="/dashboard" activeclassname="active">
              <div>{dashboardImg}</div>
              <div>Dashboard</div>
            </NavLink>
          </li>
          <li>
            <div
              className={
                pathName === "chat" ? "nav-border border_active" : "nav-border "
              }
            ></div>
            <NavLink
              to="/chat"
              className={count ? "chat_active" : "chat"}
              activeclassname="active"
            >
              <div>{count ? chatIcon : chatImg}</div>
              <div>Chat</div>
              {count !== 0 && (
                <div className="chat-count d-flex align-items-center justify-content-center f-14 text-secondary">
                  {count}
                </div>
              )}
            </NavLink>
          </li>
          {role === 2 && (
            <li>
              <div
                className={
                  pathName === "stock"
                    ? "nav-border border_active"
                    : "nav-border "
                }
              ></div>
              <NavLink to="/stock" activeclassname="active">
                <div>{stockImg}</div>
                <div>Stock</div>
              </NavLink>
            </li>
          )}
          {role === 1 && (
            // <div>
            <Accordion
              className="active cursor-pointer w-100"
              alwaysOpen={false}
            >
              <CustomToggle eventKey="0">
                <div
                  aria-hidden="true"
                  onClick={() => setArrowChange(!arrowChange)}
                  className={`accordion-toggle d-flex align-items-center w-100 ${
                    pathName === "manage" ? "active-accordian" : ""
                  }`}
                >
                  <div
                    className={
                      pathName === "manage"
                        ? "nav-border border_active"
                        : "nav-border "
                    }
                  ></div>
                  <span className="ml-4 icon">{manageUserImg}</span>
                  <div className="lable f-20 w-100 d-flex align-items-center">
                    <span> Manage user</span>
                    {/* <i
                        className={
                          arrowChange
                            ? "fa  fa-chevron-up m-2 "
                            : "fa  fa-chevron-down m-2 "
                        }
                      ></i> */}
                    <span className="m-2 down-up-arrow icon-without-stroke">
                      {arrowChange ? upArrow : downArrow}
                    </span>
                  </div>
                </div>
              </CustomToggle>
              <Accordion.Collapse eventKey="0">
                <ul className="p-2">
                  <li className="w-100">
                    <NavLink to="manage/app-user" activeclassname="active">
                      App user
                    </NavLink>
                  </li>

                  <li className="w-100">
                    <NavLink to="manage/ria-user" activeclassname="active">
                      RIA user
                    </NavLink>
                  </li>
                  <li className="w-100">
                    <NavLink to="/manage/ap-user" activeclassname="active">
                      AP user
                    </NavLink>
                  </li>
                </ul>
              </Accordion.Collapse>
            </Accordion>
            // </div>
          )}
          {role !== 1 && (
            <li>
              <div
                className={
                  pathName === "manage"
                    ? "nav-border border_active"
                    : "nav-border "
                }
              ></div>
              <NavLink to="/manage/user" activeclassname="active">
                <div>{manageUserImg}</div>
                <div>Manage user</div>
              </NavLink>
            </li>
          )}
          {role !== 1 && (
            <li>
              <div
                className={
                  pathName === "profile"
                    ? "nav-border border_active"
                    : "nav-border "
                }
              ></div>
              <NavLink
                to={`/profile/${
                  loginDetails?.role_id === 2 ? "RIA" : "AP"
                }/${userId}`}
                activeclassname="active"
              >
                <div>{person}</div>
                <div>Profile</div>
              </NavLink>
            </li>
          )}
          <li>
            <div
              className={
                pathName === "logout"
                  ? "nav-border border_active"
                  : "nav-border "
              }
            ></div>
            <a
              onClick={logutFun}
              href="/#"
              onClickCapture={(e) => e.preventDefault()}
            >
              <div className="icon-without-stroke">{logOutImg}</div>
              <div>Log out</div>
            </a>
          </li>
        </ul>
      </nav>
      <ConfirmationPopup
        show={show}
        onClose={onClose}
        size="md"
        text="Are you sure you want to logout?"
        buttonPrimary="Yes"
        buttonSecondary="Cancel"
        handleConfirm={handleConfirm}
        backdropClassName={"logout-popup"}
      />
    </div>
  );
};
export default Sidebar;
