import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Declaration from "../Components/Declaration";
import Toast from "../Components/Toast";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { updateNotificationData } from "../ReduxToolkit/notificationReducer";

import "./style.scss";
import { pusherInit } from "../Utilities";
const Layout = ({ hideSideBar }) => {
  const [showNav, setShowNav] = useState(false);
  const [status, setStatus] = useState(false);
  const loginDetails = useSelector((state) => state.login.loginData);
  const dispatch = useDispatch();

  useEffect(() => {
    const showDeclaration = localStorage.getItem("declaration");
    setStatus(showDeclaration);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Subscribe to a channel
    const channel = pusherInit.subscribe(`${loginDetails?.p_interest}`);

    // Bind to an event on the channel
    channel.bind("notification", (data) => {
      dispatch(updateNotificationData([data]));
      // Handle the data received from the event
    });

    // Clean up function
    return () => {
      // Unsubscribe from the channel when the component unmounts
      channel.unbind(); // Unbind all event handlers
      pusherInit.unsubscribe(`${loginDetails?.p_interest}`);
      // pusher.disconnect();
    };
  }, []);

  const onClose = () => {
    localStorage.setItem("declaration", "false");
    setStatus("false");
  };
  return status == "true" ? (
    <Declaration show onClose={onClose} />
  ) : (
    <>
      <Header handleNav={() => setShowNav(!showNav)} handleShow={showNav} />
      <main className="main-layout">
        <Toast />
        {!hideSideBar && <Sidebar handleShow={showNav} />}
        <div
          className={
            hideSideBar ? "main-body-without-sidebar fixed" : "main-body fixed"
          }
        >
          <Outlet></Outlet>
        </div>
      </main>
    </>
  );
};

export default Layout;
