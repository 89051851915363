import { createSlice } from '@reduxjs/toolkit';

const notificationReducer = createSlice({
    name: 'notification',
    initialState: {
      notification:[],
    },
    reducers: {
      updateNotificationData: (state, action) => {
          state.notification = action.payload;
        },
      clearNotificationData: (state) => {
        state.notification = [];
      }
    },
  });
  export const { updateNotificationData,clearNotificationData } = notificationReducer.actions;

  export default notificationReducer.reducer;