import { useState } from "react";
import { Form } from "react-bootstrap";
import CloseButton from "react-bootstrap/CloseButton";
import { BsEye, BsEyeSlash } from "react-icons/bs";

import { useNavigate } from "react-router-dom";
import Button from "../../Components/Form/Button";
import Input from "../../Components/Form/Input";
import FormModal from "../../Components/FormModal";
import { post } from "../../Services";
import { checkOldPassword, postChangePassword } from "../../Services/api";
import { errorCheck } from "../../Utilities/errorCheck";
import { showToastSuccess } from "../../Utilities/toast";
import { clearAllData } from "../../ReduxToolkit/loginReducer";

function Changepassword({ show, onClose, heading, subHeading }) {
  const [isRevealPwd1, setIsRevealPwd1] = useState(false);
  const [isRevealPwd2, setIsRevealPwd2] = useState(false);
  const [isRevealPwd3, setIsRevealPwd3] = useState(false);
  const [oldPasswordValid, setOldPasswordValid] = useState(false);
  const [formInputs, setFormInputs] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [errorObject, setErrorObject] = useState({});
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  //  const onLogOut = async() => {
  //   await localStorage.clear();
  //   await sessionStorage.clear();
  //   clearAllData();
  //   navigate("/login");
  //   window.location.reload();
  // };
  const onLogOut = () => {
    Promise.all([
      localStorage.clear(),
      // pusherInit.disconnect(),
      sessionStorage.clear(),
    ])
      .then(clearAllData)
      .then(() => {
        navigate("/login");
        // window.location.reload();
      });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormInputs({ ...formInputs, [name]: value });
    if (errorObject[name])
      setErrorObject({
        ...errorObject,
        [name]: null,
      });
  };
  const hadelApiCall = async () => {
    setLoading(true);
    const payload = {
      password: formInputs.password,
    };
    const response = await post(postChangePassword, payload);
    if (response?.status) {
      setLoading(false);
      onClose();
      showToastSuccess(response?.message);
      setTimeout(() => onLogOut(), 2000);
    } else {
      setLoading(false);
    }
  };

  const checkForOldPassword = async () => {
    const payload = {
      password: formInputs.oldPassword,
    };
    const response = await post(checkOldPassword, payload);
    if (response?.status) {
      setLoading(false);
      setOldPasswordValid(true);
      return true;
    } else {
      setOldPasswordValid(false);
      setErrorObject({ oldPassword: response?.message });
      return false;
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    if (checkForOldPassword()) {
      const newErrors = errorCheck(formInputs, "changePassword");
      if (Object.keys(newErrors).length > 0) {
        setErrorObject(newErrors);
      } else {
        hadelApiCall();
      }
    }
  };
  const handelCheckPassword = () => {
    if (formInputs.oldPassword) {
      checkForOldPassword();
    }
  };

  return (
    <FormModal
      show={show}
      onClose={onClose}
      dialogClassName="modal-400"
      closeBtnHide
      headerHide
    >
      <Form noValidate onSubmit={handleSubmit} autoComplete="off">
        <div className="d-flex justify-content-between align-items-center">
          <p className="f-32 fw-700 mb-0 ">{heading ? heading : null}</p>
          {<CloseButton onClick={() => onClose(false)} />}
        </div>
        <p className="f-16 fw-400 mt-1 text-black-light-color">
          {subHeading ? subHeading : null}
        </p>

        <div className="input-position-style">
          <Input
            type={isRevealPwd1 ? "text" : "password"}
            label="Old Password"
            name="oldPassword"
            placeholder="********"
            onChange={handleChange}
            isInvalid={errorObject.oldPassword}
            errorMessage={errorObject.oldPassword}
            onBlur={handelCheckPassword}
            disabled={oldPasswordValid ? true : false}
          />
          <span
            className="eye-icon-style"
            onClick={() => setIsRevealPwd1((prevState) => !prevState)}
            aria-hidden="true"
          >
            {" "}
            {isRevealPwd1 ? (
              <BsEye color="#BCBCBC" size={22} />
            ) : (
              <BsEyeSlash color="#BCBCBC" size={22} />
            )}{" "}
          </span>
        </div>
        <div className="input-position-style">
          <Input
            type={isRevealPwd2 ? "text" : "password"}
            label="New Password"
            name="password"
            placeholder="********"
            onChange={handleChange}
            isInvalid={errorObject.password}
            errorMessage={errorObject.password}
          />
          <span
            className="eye-icon-style"
            onClick={() => setIsRevealPwd2((prevState) => !prevState)}
            aria-hidden="true"
          >
            {" "}
            {isRevealPwd2 ? (
              <BsEye color="#BCBCBC" size={22} />
            ) : (
              <BsEyeSlash color="#BCBCBC" size={22} />
            )}{" "}
          </span>
        </div>
        <div className="input-position-style">
          <Input
            type={isRevealPwd3 ? "text" : "password"}
            label="Re-enter new password"
            placeholder="********"
            name="confirmPassword"
            onChange={handleChange}
            isInvalid={errorObject.confirmPassword}
            errorMessage={errorObject.confirmPassword}
          />
          <span
            className="eye-icon-style"
            onClick={() => setIsRevealPwd3((prevState) => !prevState)}
            aria-hidden="true"
          >
            {isRevealPwd3 ? (
              <BsEye color="#BCBCBC" size={22} />
            ) : (
              <BsEyeSlash color="#BCBCBC" size={22} />
            )}
          </span>
        </div>
        <Button
          type="submit"
          className="mt-25 w-100 bg-primary text-white f-16 fw-700 btn-padding"
          loading={isLoading}
          disabled={
            !formInputs?.oldPassword ||
            !formInputs.password ||
            !formInputs.confirmPassword
              ? true
              : !oldPasswordValid
              ? true
              : false
          }
        >
          Update Password
        </Button>
      </Form>
    </FormModal>
  );
}

export default Changepassword;
