
import Pusher from "pusher-js";

export const getQueryParam = () => {
  const params = window.location.pathname.substring(1);
  return params;
};
function convertUtcToIndianTime(utcTimeString) {
  const date = new Date(utcTimeString);

  const options = {
    timeZone: "Asia/Kolkata", // Indian Standard Time
    hour12: true, // Use 12-hour clock format
    // weekday: 'long',
    // year: 'numeric',
    // month: 'long',
    // day: 'numeric',
    hour: "numeric",
    minute: "numeric",
  };

  const istDateString = date.toLocaleString("en-IN", options);
  return istDateString;
}
export const currentYear = new Date().getFullYear();
export const formatDate = (value) => {
  const date = new Date(value);
  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export function createEmptyObjectWithNames(arrayOfObjects) {
  const emptyObject = {};
  arrayOfObjects.forEach((obj) => {
    emptyObject[obj.name] = "";
  });
  return emptyObject;
}
export const isDateOfBirthValid = (dateOfBirth) => {
  // Define a regular expression pattern for DD/MM/YYYY format
  const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;

  if (!dateFormat.test(dateOfBirth)) {
    // If the input doesn't match the pattern, it's invalid
    return false;
  }

  // Parse the input string into a Date object
  const parts = dateOfBirth.split("/");
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Months are 0-based
  const year = parseInt(parts[2], 10);

  // Create a Date object and check if it's a valid date
  const dob = new Date(year, month, day);

  return (
    dob.getDate() === day &&
    dob.getMonth() === month &&
    dob.getFullYear() === year
  );
};

export const dateFormatConversion = (value) => {
  const [day, month, year] = value.split("/");
  const formattedDateStr = `${month}/${day}/${year}`;

  // Parse the formatted date string into a JavaScript Date object
  const date = new Date(formattedDateStr);
  const formattedDateString = date.toLocaleDateString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
    timeZone: "Asia/Kolkata", // Change this to your specific timezone
  });
  return formattedDateString;
};
export const formatDateTime = (inputDateTime, outputFormat) => {
  const date = new Date(inputDateTime);

  if (isNaN(date)) {
    return "Invalid Date";
  }

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  const formats = {
    "dd/mm/yyyy": `${day}/${month}/${year}`,
    "mm/dd/yyyy": `${month}/${day}/${year}`,
    "yyyy-mm-dd": `${year}-${month}-${day}`,
    "hh:mm:ss": `${hours}:${minutes}:${seconds}`,
    "dd/mm/yyyy, hh:mm": `${day}/${month}/${year}, ${hours}:${minutes}`,
  };

  return formats[outputFormat] ?? "Unsupported Format";
};

export function timeAgo(dateString) {
  const currentDate = new Date();
  const inputDate = new Date(dateString);
  const timeDifference = currentDate - inputDate;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const years = Math.floor(days / 365);
  if (years > 0) {
    return years + (years === 1 ? " year ago" : " years ago");
  } else if (days > 0) {
    return days + (days === 1 ? " day ago" : " days ago");
  } else if (hours > 0) {
    return hours + (hours === 1 ? " hour ago" : " hours ago");
  } else if (minutes > 0) {
    return minutes + (minutes === 1 ? " minute ago" : " minutes ago");
  } else {
    return "Just now";
  }
}
export const pusherInit = new Pusher("d6369c2f3006e7a644ea", {
  cluster: "ap2",
  encrypted: false,
  disableStats: true,
  // Add any other options here if needed
});
//
export function formatDateResponse(incomingDate) {
  "2023-11-14";
  // Parse the incoming date string in "dd/mm/yyyy" format
  const [year, month, day] = incomingDate.split("-").map(Number);
  const parsedDate = new Date(year, month - 1, day); // Month is 0-based in JavaScript Date object

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  if (parsedDate.toDateString() === today.toDateString()) {
    return "Today";
  } else if (parsedDate.toDateString() === yesterday.toDateString()) {
    return "Yesterday";
  } else {
    // Format the date in "dd/mm/yyyy" format
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }
}
export function checkToday(incomingDate) {
  const utcDate = new Date(incomingDate);
  const currentDate = new Date();
  const isToday =
    utcDate.getDate() === currentDate.getDate() &&
    utcDate.getMonth() === currentDate.getMonth() &&
    utcDate.getFullYear() === currentDate.getFullYear();

  return isToday;
}

export function checkDate(incomingDate) {
  if (checkToday(incomingDate)) {
    return convertUtcToIndianTime(incomingDate);
  } else {
    return timeAgo(incomingDate);
  }
}

// alphabetical month display date
export function formatAlphabetMonth(date) {
  const [stringDay, stringMonth, stringYear] = date.split("-");

  // Define months array
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get month, day, and year
  const month = months[parseInt(stringMonth) - 1];
  const day = stringDay;
  const year = stringYear;

  // Create formatted date string
  const formattedDate = `${month} ${day}, ${year}`;

  return formattedDate;
}
export function isNumber(array) {
  // If array is empty, return false
  if (array.length === 0) {
    return false;
  }
  // Check if every element is a number
  return array.every(value => typeof value === 'number' && !isNaN(value));
}
export const formatDateForSocket = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because months are zero-based
  const day = currentDate.getDate().toString().padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const truncateString = (str, num) =>
  str?.length > num ? `${str.slice(0, num)}...` : str;

export const objectLengthCheck = (data) => {
  return Object.keys(data)?.length > 0;
};
export const convertToTwoDigitFormat = (number) => {
  return number.toString().padStart(2, "0");
};

export const bytesToMB = (bytes) => {
  return (bytes / 1024 / 1024).toFixed(2);
};

export const generateRandomName = () => {
  // Generate a unique identifier, such as a timestamp or UUID
  const uniqueIdentifier = Date.now().toString();

  // Get the file extension from the original audioBlob
  const fileExtension = "wav"; // Update this based on your actual file extension

  // Combine the unique identifier and file extension to create a random name
  const randomName = `${uniqueIdentifier}.${fileExtension}`;

  return randomName;
};
