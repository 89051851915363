import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.scss";

function Toast() {
 
  return (
    <ToastContainer
      theme="light"
      position="top-right"
      hideProgressBar
      autoClose={3000}
      closeButton={false}
    />
  );
}

export default Toast;
