export const postLogin = "authninja/api/login";
export const postForgotPassword = "authninja/api/forget-password";
export const postResetPassword = "authninja/api/reset-password";
export const postChangePassword = "authninja/api/change-password";
export const postDeclaration = "authninja/api/declaration";
export const postExpiryCheck = "authninja/api/verify-reset-link";
export const getDeactivateReason = "userhub/api/user-deactivated-details";

// Dashboard-----
export const getAdminDashboard = "userhub/api/admin-dashboard";
export const getIncomingRequest = "userhub/api/incoming-request";

// AP user-----
// AP useruser-profile/
// AP Listing

export const postAPUser = "userhub/api/create-ap";
export const getViewApProfile = "userhub/api/ap-user";
export const getAPDashboard = "userhub/api/ap-dashboard";

// stock execution
export const postPendingStocks = "tradesman/api/pending-execution";
export const postPreviousStocks = "tradesman/api/executed-stock";
export const postExecuteStock = "tradesman/api/stock-execute";
export const cDSLCall = "tradesman/api/intiate-cdsl-process";

// ManageAppUSerList
export const postUserList = "userhub/api/list-users";
export const getProfileDetails = "userhub/api/user-profile";
export const getApList = "userhub/api/role-by-user-list?role=ap";
export const getRiaList = "userhub/api/role-by-user-list?role=ria";
export const postEditDetails = "userhub/api/user-assign-ria-ap";
export const postChoiceConnection = "userhub/api/choice-status";
export const updateUserStatus = "/userhub/api/user-enable-disable";
export const RiaApStatus = "/userhub/api/ria-ap-account-manage";
export const postBulkReassign = "/userhub/api/user-bulk-reassign-ria-ap";

// RIA and AP user -----
export const postAssignedUserList = "userhub/api/assigned-users";
export const updateAPUser = "userhub/api/update-ap-user-profile";

//RIA user -----
export const getViewRiaProfile = "userhub/api/ria-user";
export const getRIADashboard = "userhub/api/ria-dashboard";

//view ria profile

export const postRIAUser = "userhub/api/create-ria";
export const updateRIAUser = "userhub/api/update-ria-user-profile";

// Admin user profile -----
export const verifyDetails = "userhub/api/profile-additional-verify";
export const postAddtionalDetails = "userhub/api/profile-additional-info";
export const getAllDocuments = "userhub/api/user-documents";
export const updateDocumentsStatus = "userhub/api/user-documents-status";
export const getDeleteReason = "userhub/api/user-deleted";


// Change password -----
export const checkOldPassword = "authninja/api/verify-old-password";

//Dashboard tutorials
export const updateTutorialStatus = "userhub/api/dashboard-tutorial-status";

// RIA Dashboard
export const PostStockList = "tradesman/api/stock-list";
export const postRecentStocks = "tradesman/api/stock-suggestion-list";
export const marketStatus = "tradesman/api/web-market-status"
// Stock
export const PostRecommendedStock = "tradesman/api/stock-suggestion-list";
//view ManageUserProfile

export const viewManageAppUser = "userhub/api/view-user-details";

//suggest Stock

export const addSuggestStock = "tradesman/api/ria-stock-suggestion";
export const getDetailsSuggestedStock = "tradesman/api/ria-stock-suggestion";

//Chat

export const adminChatList = "userhub/api/admin-chat-user-list";
export const riaChatList = "userhub/api/chat-user-list";
export const apChatList = "userhub/api/chat-user-list";
export const contactList = "userhub/api/chat-contact-list";
export const adminChatHistory = "userhub/api/admin-chat-msg-history";
export const chatHistory = "userhub/api/chat-msg-history";
export const sendMessage = "userhub/api/sent-chat-message";
export const postCreateContact = "userhub/api/chat-add-contact";
export const postStatusUpdate = "userhub/api/chat-user-online-status";
export const getChatCount = "userhub/api/get-chat-menu-unread-count";

//notification
export const getNotification = "/tasktiger/api/notification-list";
export const clearAllNotification = "/tasktiger/api/clear-all";
export const markAllAsRead = "/tasktiger/api/mark-as-read";

//userTransactionHistory
export const getUserTransactionHistory = "/tradesman/api/transaction-history";
