import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../../Assets/images/logo-freeman.svg";
import { changePasswordIcon, person } from "../../Assets/images/svg";
import ConfirmationPopup from "../../Components/ConfirmationPopup";
import { clearNotificationData } from "../../ReduxToolkit/notificationReducer";
import { truncateString } from "../../Utilities";
import Changepassword from "./changepassword";
import Notification from "./notification";
import "./style.scss";

const Header = ({ handleNav }) => {
  const navigate = useNavigate();
  const loginDetails = useSelector((state) => state.login.loginData);
  const role = loginDetails?.role_id;
  const [logoutShowModal, setLogoutShowModal] = useState(false);
  const [arrowChange, setArrowChange] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showNotificationIcon, setShowNotificationIcon] = useState(false);
  const notificationDetails = useSelector(
    (state) => state.notification.notification
  );
  const isNotification=localStorage.getItem("notification")

  const dispatch = useDispatch();
  const viewProfile = () => {
    navigate(
      `/profile/${loginDetails?.role_id === 2 ? "RIA" : "AP"}/${
        loginDetails?.user_id
      }`
    );
  };

  const handleConfirm = () => {
    setLogoutShowModal(false);
    localStorage.clear();
    // pusherInit.disconnect();
    sessionStorage.clear();
    navigate("/");
  };
  const handelShowAndHideNotification = () => {
    localStorage.removeItem("notification");
    dispatch(clearNotificationData());
    setShowNotification(!showNotification);
  };
  useEffect(() => {
    if (notificationDetails?.length > 0) {
      setShowNotificationIcon(true);
    } else {
      setShowNotificationIcon(false);
    }

    // eslint-disable-next-line
  }, [notificationDetails]);

  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg ">
        <div className="container-fluid ps-0">
          <a
            className="navbar-brand"
            href="/#"
            onClick={() => navigate("/dashboard")}
            onClickCapture={(e) => e.preventDefault()}
          >
            <img src={Logo} alt="logo" />
          </a>
          <button className="navbar-toggler" type="button" onClick={handleNav}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto d-flex align-items-center ">
              <li className="nav-item ">
                <a
                  className="nav-link active "
                  aria-current="page"
                  href="/#"
                  onClickCapture={(e) => e.preventDefault()}
                >
                  <span className={`notification-bell ${showNotification?"notification-bell-active":""}`}>
                    <i
                      className={`fa fa-bell-o  text-text3 ${showNotification?"active-bell":""}`}
                      onClick={handelShowAndHideNotification}
                    ></i>
                   
                    {(showNotificationIcon || Number(isNotification)) ? (
                      <span className="badge-icon"></span>
                    ) : null}
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <div className="nav-item-separtor"></div>
              </li>
              <li className="nav-item">
                <Dropdown onToggle={() => setArrowChange(!arrowChange)}>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="pt-1 px-0"
                  >
                    <div className="user-section">
                      <div className="user-icon">
                        {loginDetails?.username?.slice(0, 1)?.toUpperCase()}
                      </div>
                      <div className="content">
                        <div className="me-3">
                          <div
                            className="lable f-16 w-100 d-flex align-items-center text-primary-black"
                            // onClick={() => setArrowChange(!arrowChange)}
                          >
                            <span title={loginDetails?.username}>
                              {truncateString(loginDetails?.username, 10)}
                            </span>
                            {role !== 1 && (
                              <i
                                className={
                                  arrowChange
                                    ? "fa fa-thin fa-chevron-up m-2 f-12"
                                    : "fa fa-thin fa-chevron-down m-2 f-12"
                                }
                              ></i>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu align="end">
                    {role !== 1 && (
                      <>
                        {" "}
                        <Dropdown.Item
                          className="fw-500 text-text2 user-btn"
                          as="button"
                          onClick={() => setArrowChange(!arrowChange)}
                        >
                          <div
                            aria-hidden="true"
                            className="user-dropdown d-flex gap-3 align-items-center"
                            onClick={viewProfile}
                          >
                            {person}Profile
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="fw-500 text-text2"
                          as="button"
                          onClick={() => setShowChangePassword(true)}
                        >
                          <div className="user-dropdown d-flex gap-3 align-items-center f-16 fw-500">
                            {changePasswordIcon}Change Password
                          </div>
                        </Dropdown.Item>
                      </>
                    )}
                    {/* <Dropdown.Item
                      className="fw-500 text-text2"
                      as="button"
                      onClick={handlShowLogout}
                    >
                      <div className="user-dropdown d-flex gap-3 align-items-center f-16 fw-500" onClick={handlShowLogout}>{logOutImg}Logout</div>
                    </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {showNotification ? (
        <div className="notification-backdrop">
          <Notification
            closeOnOutsideClick={handelShowAndHideNotification}
          ></Notification>
        </div>
      ) : null}

      <ConfirmationPopup
        show={logoutShowModal}
        onClose={() => setLogoutShowModal(false)}
        size="md"
        text="Are you sure you want to logout?"
        buttonPrimary="Yes"
        buttonSecondary="Cancel"
        handleConfirm={handleConfirm}
      />
      {showChangePassword && (
        <Changepassword
          show={showChangePassword}
          onClose={() => setShowChangePassword(false)}
          heading={"Change Password"}
        />
      )}
    </header>
  );
};

export default Header;
