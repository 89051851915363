import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Form/Button";
import NoData from "../../Components/NoData";
import { post } from "../../Services";
import { getNotification, markAllAsRead } from "../../Services/api";
import { timeAgo } from "../../Utilities";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { clearNotificationData } from "../../ReduxToolkit/notificationReducer";

function Notification({ closeOnOutsideClick }) {
  const divRef = useRef(null);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [readCount, setReadCount] = useState(0);
  const [notificationList, setNotificationList] = useState([]);
  const notificationDetails = useSelector(
    (state) => state.notification.notification
  );
  const dispatch=useDispatch();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        closeOnOutsideClick();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const getNotificationList = async () => {
    if(notificationDetails?.length>0){
      setLoader(true);
    }
    const payLoad = {
      page: 1,
      page_size: 5, // change page size according to list length
      search: "",
    };
    const response = await post(`${getNotification}`, payLoad);

    if (response?.status) {
      setLoader(false);
      setNotificationList(response?.data);
      setReadCount(response?.unread_count)
    } else {
      setLoader(false);
    }
  };
  const updateAllReadStatus = async (parms = {}) => {
    const payload = {
      is_read_all:parms?._id?false:true,
      ...parms,
    };
    
    const response = await post(`${markAllAsRead}`, payload);
    if (response?.status && !parms?._id) {
      dispatch(clearNotificationData());
      getNotificationList();
    }
  };

  useEffect(() => {
    // Call the function to get incoming request data when the component mounts
    
    getNotificationList();
    // eslint-disable-next-line
  }, [notificationDetails]);
  const onNavigate = (pathName,notificationId,seen) => {
    if (notificationId && !seen) {
      updateAllReadStatus({ _id: notificationId });
    }
    navigate(pathName);
    closeOnOutsideClick();

   
  };
  
  return (
    <div className="notification-details" ref={divRef}>
      <div className="d-flex justify-content-between align-items-center  notification-header">
        <h2 className="f-22 fw-600 m-0">Notifications</h2>
        <Button
          className="transparent-btn f-16 fw-400 cursor-pointer"
          onClick={()=>updateAllReadStatus()}
          id="viewAll"
          disabled={readCount===0}
        >
          Mark all as read
        </Button>
      </div>
     
      {notificationList?.length === 0 ? (
        loader?null:<NoData text="No notification found" />
      ) : (
        <div className="notification-content custom-scroll">
          {notificationList.map((item) => {
            return (
              <Row
                key={item?._id}
                className="notification-content-list cursor-pointer"
                onClick={() => onNavigate(item?.target_route,item?._id,item?.seen)}
              >
                <Col md={2}>
                  <div
                    className={`profile-icon-label  d-flex align-items-center justify-content-center f-24 fw-500  ${
                      item?.username === "Admin"
                        ? "admin-profile-color"
                        : "user-profile-color"
                    }`}
                  >
                    <span>{item?.username?.charAt(0).toLocaleUpperCase()}</span>
                    {item?.seen ? null : (
                      <div className="notification-dot"></div>
                    )}
                  </div>
                </Col>
                <Col md={10} className="d-flex flex-column gap-1">
                  <p className="f-16 fw-400 text-black-light-color m-0">
                    {item.message}
                  </p>
                  <div className="d-flex align-items-center gap-2 f-14 fw-400 text-grey-color">
                    <div>{timeAgo(item.created_at)}</div>
                    <div className="footer-dot"></div>
                    <div>{item?.username}</div>
                  </div>
                </Col>
              </Row>
            );
          })}
        </div>
      )}
     
      <div className="notification-footer d-flex justify-content-center align-items-center">
        <Button
          className="transparent-btn f-16 fw-600 cursor-pointer footer-btn"
          onClick={() => {
            onNavigate("/dashboard/notification");
          }}
          id="viewAll"
        >
          See more <FiArrowRight size={16} />
        </Button>
      </div>
    </div>
  );
}

export default Notification;
