import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import tableReducer from "./tableReducer";
import notificationReducer from "./notificationReducer";

const rootReducer = combineReducers({
  login: loginReducer,
  table:tableReducer,
  notification:notificationReducer
});

export default rootReducer;
