import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { post } from "../../Services";
import { postDeclaration } from "../../Services/api";
import Button from "../Form/Button";
import "./style.scss";
import { clearAllData } from "../../ReduxToolkit/loginReducer";

function Declaration({ onClose }) {
  const navigate = useNavigate();
  const [accepted, setAccepted] = useState(false);
  const [declaredKey, setDeclaredKey] = useState({
    terms: false,
    policy: false,
  });
  const termsContentRef = useRef(null);
  const loginDetails = useSelector((state) => state?.login?.loginData);

  useEffect(() => {
    const element = termsContentRef.current;
    const handleScroll = () => {
      if (element) {
        // Calculate the scrollable height by subtracting the container's client height
        const scrollableHeight = element.scrollHeight - element.clientHeight;

        // Check if the user has scrolled to the bottom of the terms content
        if (Math.ceil(element.scrollTop) >= scrollableHeight) {
          setAccepted(true);
        }
      }
    };

    // Add scroll event listener
    element.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const divElement = termsContentRef.current;
  //     if (divElement) {
  //       // Calculate the scrollable height by subtracting the container's client height
  //       const scrollableHeight = divElement.scrollHeight - divElement.clientHeight;
  //           console
  //       // Check if the user has scrolled to the bottom of the div
  //       if (Math.ceil(divElement.scrollTop) >= scrollableHeight) {
  //         setAccepted(false);
  //       } else {
  //         setAccepted(true);
  //       }
  //     }
  //   };

  //   const divElement = setAccepted.current;
  //   if (divElement) {
  //     divElement.addEventListener("scroll", handleScroll);
  //   }

  //   return () => {
  //     if (divElement) {
  //       divElement.removeEventListener("scroll", handleScroll);
  //     }
  //   };
  // }, []);

  const onSubmitForm = async (e) => {
    e.preventDefault();
    const payload = {
      declaration: false,
    };
    const declarationResponse = await post(postDeclaration, payload);
    if (declarationResponse?.status) {
      localStorage.setItem("declaration", "false");
      onClose();
    }
  };
  const handleCheckChange = (e) => {
    setDeclaredKey({ ...declaredKey, [e.target.name]: e.target.checked });
  };

  const logoutFn = () => {
    Promise.all([localStorage.clear(),
      //  pusherInit.disconnect(), 
       sessionStorage.clear()])
    .then(clearAllData)
    .then(() => {
      navigate("/login");
      // window.location.reload();
    });
  };

  return (
    <div className="declaration-page p-4">
      <div className="content-container">
        <div className=" d-flex justify-content-between align-items-center flex-wrap heading">
          <div className="f-32 fw-700">
            {" "}
            {`Declaration for ${
              loginDetails?.role_id === 2 ? "RIA" : "AP"
            }`}{" "}
          </div>
          <div className="f-18 fw-400 text-black-light-color">
            Last updated on 9/08/2022
          </div>
        </div>
        <div className="decalaration-content" ref={termsContentRef}>
          <div>
            <h3 className="f-24 fw-500 text-start">
              1.Freeman’s Terms & Conditions
            </h3>
            <p className="f-18 fw-400 text-black-light-color">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It
              has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It
              has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It
              has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It
              has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It
              has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It
              has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It
              has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It
              has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It
              has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
          </div>
          <div>
            <h3 className="f-24 fw-500 text-start">2.Freeman’s Policy</h3>
            <p className="f-18 fw-400 text-black-light-color">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It
              has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It
              has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It
              has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It
              has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It
              has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It
              has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It
              has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It
              has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It
              has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
          </div>
        </div>
        {
          <Form
            className="footer p-3  d-flex justify-content-between align-items-center flex-wrap"
            onSubmit={onSubmitForm}
          >
            <div>
              {[
                {
                  name: "I agree with Terms & conditions",
                  type: "checkbox",
                  key: "terms",
                },
                {
                  name: "I agree with Policy",
                  type: "checkbox",
                  key: "policy",
                },
              ].map((item) => (
                <div key={`default-${item.type}`} className="mb-3">
                  <Form.Check // prettier-ignore
                    type={item.type}
                    id={`default-${item.name}`}
                    label={`${item.name}`}
                    name={item.key}
                    required
                    className="d-flex gap-2"
                    disabled={accepted ? false : true}
                    onChange={handleCheckChange}
                  />
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-center  justify-content-sm-between align-items-center flex-wrap gap-4">
              <Button
                className="grey-outline-btn btn-padding f-16 fw-400"
                type="button"
                onClick={logoutFn}
              >
                Decline
              </Button>
              <Button
                className="primary-btn btn-padding f-16 fw-700"
                type="submit"
                disabled={
                  !declaredKey.policy || !declaredKey.terms ? true : false
                }
              >
                Agree and Continue
              </Button>
            </div>
          </Form>
        }
      </div>
    </div>
  );
}

export default Declaration;
